.mobile-only{
    display: none;
}



@media only screen and (max-width: 600px) {
    .mobile-only {
        display: block;
    }
}
