.Home {
  background-color: rgba(245, 245, 220, 0.589);
  min-height: 100vh;
}



input.button {
    background-color: darkgrey;
    border: solid;
    color: black;
    padding: 15px 20px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin: 4px 2px;
    cursor: pointer;
  }


.profile-image {
  max-width: 60vw;
  max-height: 64vh;
  padding-right: 20px;
}

.profile-image>img{
  max-width: 400px;
}

.profile-text{
  text-align: left;
  font-size: 20px;
  font-family: Arial, Helvetica, sans-serif;
  line-height: 35px;
}

.profile-text > p{
  margin-top: 0;
}

.profile {
  display: flex;
  width: 70%;
  margin: auto;
  margin-left: 15%;
  padding-top: 20px;
}


  
@media only screen and (max-width: 600px) {
  /* For mobile: */
  .profile {
    display: block;
    width: 80%;
    margin-left:auto;
  }

  .profile-image>img{
    max-width: 100%;
  }
  .profile-image{
    margin: auto;
  }

  .profile-text{
    text-align: left;
    font-size: 20px;
    font-family: Arial, Helvetica, sans-serif;
    line-height: 35px;
    height: auto;
    overflow: visible;
    margin-top: 10px;
  }

}


@media only screen and (max-width: 600px) {

  .Home{
    padding-bottom: 100px;

  }
}