.Portfolio-Page{
    
  background-color: rgba(245, 245, 220, 0.589);
  min-height: 100vh;
  padding-bottom: 50px;
}
.portfolio-section {
    width: 80%;
    margin: auto;
    text-align: left;
}

.portfolio-row {
    display: flex;
}

.portfolio-link {
    text-decoration: none;
    display: block;
    color: black;
    width: 50%;
    background-color: lightgrey;
    margin: 20px;
    padding: 20px;
    border-radius: 10px;
    cursor: pointer;
}

.portfolio-text {
    display: flex;
}
.portfolio-text > span {
    margin-top: auto;
    margin-bottom: auto;
    padding-left: 20px;
}
/* 
.portfolio-left, .portfolio-right{
    width: 50%;
    background-color: lightgrey;
    margin: 20px;
    padding: 20px;
    border-radius: 10px;
    cursor: pointer;
} */


.portfolio-item-header {
    display: flex;
}


.portfolio-item-header > h3 {
    margin-left: 10px;
}

.portfolio-item-header > svg {
    margin-top: auto;
    margin-bottom: auto;
}

.app-image {
    text-align: center;
}
.app-image > img {
    width: 50%;
}



@media only screen and (max-width: 600px) {
    .portfolio-section {
        width: fit-content;
        margin: auto;
        text-align: left;
        margin-bottom: 80px;
    }
    
    .portfolio-row {
        display: block;
    }
    .portfolio-link{
        margin-left: auto;
        margin-right: auto;
    }
    .portfolio-text{
        display: block;
    }
    .portfolio-text > span {
        display: none;
    }

    .portfolio-item-header{
        display: block;
        text-align: center;
    }
}