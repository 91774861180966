ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
  }
  
  li {
    display: inline;
    padding: 20px;
  }

  li> a {
    color: white;
    text-decoration: none;
  }

  
@media only screen and (max-width: 600px) {
  .header-list{
    width: inherit;
  }

  .header-list > li {
    padding: 3%;
  }

}