.resume {
    padding-bottom: 50px;
}
.Resume-Page{
    background-color: rgba(245, 245, 220, 0.589);
    min-height: 100vh;
}
.about-header{
    margin-top: 30px;
    font-size: 30px;
}

.about-nav {
    font-size: 20px;
    margin-top: 20px;
}
.about-section {
    padding: 20px;
    width: 80%;
    margin: auto;
    text-align: left;
    margin-bottom: 20px;
}

li.button, h2.button>a {
    background-color: rgba(211, 211, 211, 0.285);
    border: none;
    border-radius: 3cm;
    color: black;
    padding: 10px 15px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 20px;
    margin: 20px 10px;
    cursor: pointer;
    text-decoration: none;
}

.sub-header{
    font-size: 20px;
    font-weight: bold;
}

.hidden {
    display: none;
}


h2 > a {
    font-size: 14px;
}

/* Skill Section CSS */
.skill-list>li{
    display: block;
    line-height: 20px;
    font-size: 20px;
}

.skill-level>li{
    font-weight: bold;
}


.skill-section{
    display: flex;
    width: fit-content;
    margin: auto;
}


/* Work Section CSS */
.work-experience {
    width: fit-content;
    margin: auto;
}
.work-duty-list>li{
    display: block;
    line-height: 20px;
    font-size: 20px;
    max-width: 300px;
}

.work-section {
    display: flex;
}
.work-left {
    margin-right: 50px;
}

.work-section > div > h3 {
    font-weight: 800;
}


/* Education Section CSS */
.education {
    width: fit-content;
    margin: auto;
}
.course-list>li{
    display: block;
    line-height: 20px;
    font-size: 20px;
}

.education-section {
    display: flex;
}
.education-left {
    margin-right: 50px;
}


    /* Certifications Section CSS */
.certifications{
    width: fit-content;
    margin: auto;
}

.certificate-section{
    margin-left: 50px;
}
.certificate-section > h2 , .certificate-section > h3 {
    color: darkgreen;
}

.certificate-section > .course-list > li {
    display: block;
    font-size: 18px;
    line-height: 20px;
    padding-top: 10px;
    padding-bottom: 10px;
}


/* Mobile Section CSS */
@media only screen and (max-width: 600px) {

    
    /* Mobile Skills Section CSS */

    .skill-right{
        display: none;
    }

    .skill-list>li{
        display: inline-flex;
        padding-left: 0px;
        padding-right: 0px;
        padding-bottom: 0px;
        width: 100%;
    }
    li>.skill-name{
        width: 75%;
        margin: auto;
    }
    li>.skill-level.mobile-only{
        width: 25%;
        margin-top: auto;
        margin-bottom: auto;
    }

    .skill-level.mobile-only{
        text-align: right;
        right: 0;
        position: relative;
    }
        
    /* Mobile Education Section CSS */

    .education-section {
        display: block;
    }


     
    /* Mobile Work Section CSS */

    .work-section {
        display: block;
    }
}
